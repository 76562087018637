import React from "react";
import ProductLayout from "../../../components/Layout/productLayout";
import ProductNavigation from "../../../components/Layout/productNavigation";
import { Disclosure } from "@headlessui/react";
import { useStaticQuery, graphql } from "gatsby";
import SEO from "../../../components/seo";


const Faq = () => {
  const query = useStaticQuery(graphql`
    {
      prismicProducts(uid: { eq: "kollegio" }) {
        data {
          seo_title
          seo_description
          question {
            question1 {
              text
            }
            answer {
              text
            }
          }
          product_name {
            text
          }
        }
      }
    }
  `);

  return (
    <>
    <SEO 
    title = {query.prismicProducts.data.seo_title}
    desc = {query.prismicProducts.data.seo_description}/>
      <ProductLayout>
        <ProductNavigation
          productName="Kollegio"
          featuresLink="/products/kollegio/"
          buyLink="/products/kollegio/buy"
          techSpecsLink="/products/kollegio/specs"
          faqLink="/products/kollegio/faq"
        />

<div className="my-5 mx-5 md:max-w-screen-sm lg:max-w-screen-md xl:max-w-screen-lg md:mx-auto">
          <div className="grid grid-cols-1 gap-1">
            <div className=" py-5 border-b">
              <h4 className="text-2xl text-gray-700 font-semibold">F.A.Q</h4>
            </div>
            {query.prismicProducts.data.question.map(
              (question) => {
                return (
                  <>
                    <div className="py-5 flex flex-col space-y-2">
                      <h4 className="text-xl font-medium text-gray-500">
                        {question.question1.text}
                      </h4>
                      <p className="text-base font-regular text-gray-400">
                        {question.answer.text}
                      </p>
                    </div>
                  </>
                );
              }
            )}
          </div>
        </div>
      </ProductLayout>
    </>
  );
};

export default Faq;
